import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import Helmet from 'react-helmet';
import { useSiteMetaData, useLanguage } from '../hooks';

const SEO = ({ page }) => {
  const language = useLanguage();
  const site = useSiteMetaData();

  const getPage = (nodes) => {
    const {
      childMarkdownRemark: {
        frontmatter: { pages },
      },
    } = nodes.filter((el) => el.relativeDirectory === language)[0];
    return pages.filter((el) => el.page === page)[0];
  };

  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "metadata" } }) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              pages {
                description
                keywords
                page
                title
              }
            }
          }
          relativeDirectory
        }
      }
    }
  `);
  const metadata = getPage(nodes);

  return (
    <Helmet
      htmlAttributes={{
        language,
      }}
      title={metadata.title}
      titleTemplate={`%s | ${site.title}`}
      meta={[
        {
          name: 'description',
          content: metadata.description,
        },
        {
          name: 'keywords',
          content: metadata.keywords,
        },
        {
          property: 'og:title',
          content: metadata.title,
        },
        {
          property: 'og:description',
          content: metadata.title,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.author,
        },
        {
          name: 'twitter:title',
          content: metadata.title,
        },
        {
          name: 'twitter:description',
          content: metadata.description,
        },
      ]}
    />
  );
};

SEO.propTypes = {
  page: PropTypes.string.isRequired,
};

export default SEO;
