import React, { useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const LanguageContext = createContext();

const LanguageProvider = ({ children, language }) => (
  <LanguageContext.Provider value={language}>
    {children}
  </LanguageContext.Provider>
);

LanguageProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  language: PropTypes.string.isRequired,
};

const useLanguage = () => useContext(LanguageContext);

export { LanguageContext, LanguageProvider, useLanguage };
