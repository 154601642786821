import Container from '@material-ui/core/Container';
import styled from '@material-ui/styles/styled';

const SectionContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center',
  },
  paddingTop: '2rem',
  paddingBottom: '2rem',
}));

export default SectionContainer;
