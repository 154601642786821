/* eslint-disable jsx-a11y/accessible-emoji */
import PropTypes from 'prop-types';
import React from 'react';
import Select from '@material-ui/core/Select';
import AppBar from '@material-ui/core/AppBar';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Toolbar from '@material-ui/core/Toolbar';
import styled from '@material-ui/styles/styled';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { useCookies } from 'react-cookie';
import FlagSE from 'svg-country-flags/svg/se.svg';
import FlagGB from 'svg-country-flags/svg/gb.svg';

import { translate } from '../../lib';
import {
  useStyles,
  useLanguage,
  usePrefix,
  getPrefixFromValue,
} from '../../hooks';
import ElevationScroll from './ElevationScroll';

const Item = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'center',
});

const Header = ({ pathname }) => {
  const prefix = usePrefix();
  const language = useLanguage();
  const setCookies = useCookies(['language'])[1];

  const data = useStaticQuery(graphql`{
  file(relativePath: {eq: "nav_logo.png"}) {
    childImageSharp {
      gatsbyImageData(width: 116, layout: FIXED)
    }
  }
}
`);

  const links = [
    {
      href: `${prefix}/`,
      label: translate({ en: 'Home', sv: 'Hem' }),
    },
    {
      href: `${prefix}/join`,
      label: translate({ en: 'Join Us', sv: 'Gå med' }),
    },
    {
      href: `${prefix}/contact`,
      label: translate({ en: 'Contact', sv: 'Kontakt' }),
    },
  ];

  const classes = useStyles();

  const NavLink = ({ href }) => <Link to={href} />;

  NavLink.propTypes = {
    href: PropTypes.string.isRequired,
  };

  const CustomFlag = ({ flag, alt, ...rest }) => (
    <img src={flag} className={classes.flag} height="14" alt={alt} {...rest} />
  );

  CustomFlag.propTypes = {
    flag: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  };

  return (
    <ElevationScroll
      isFirstPage={pathname === '' || pathname === `/${prefix}`}
      topClass={classes.appBarTop}
    >
      <AppBar style={{ transition: 'background-color ease 0.5s' }}>
        <div className={classes.brandContainerDesktop}>
          <Link to={links[0].href} style={{ zIndex: 99, display: 'flex' }}>
            <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="Nav logo" />
          </Link>
        </div>
        <Toolbar classes={{ root: classes.toolbar }} variant="dense">
          {/* Disable navigation for now as we have only one page.
          <div className={classes.tabs}>
            {links.map(({ href, label }) => (
              <Link
                key={href}
                to={href}
                activeClassName={classes.activeTab}
                className={classes.tab}
              >
                {label}
              </Link>
            ))}
          </div>
           */}
          <Select
            classes={{ icon: classes.selectIcon, root: classes.selectRoot }}
            input={<InputBase />}
            value={language}
            SelectDisplayProps={{
              'aria-label': 'Language select',
            }}
            onChange={event => {
              const { value } = event.target;
              navigate(pathname.replace(prefix, getPrefixFromValue(value)));
              setCookies('language', value, { path: '/' });
            }}
          >
            <Item value="sv" classes={{ root: classes.flagContainer }}>
              <CustomFlag flag={FlagSE} alt="Swedish" />
            </Item>
            <Item value="en" classes={{ root: classes.flagContainer }}>
              <CustomFlag flag={FlagGB} alt="English" />
            </Item>
          </Select>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Header;
