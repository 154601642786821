import { cloneElement } from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';

const ElevationScroll = ({ children, topClass, isFirstPage }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const props = {
    elevation: trigger ? 4 : 0,
  };

  if (isFirstPage && !trigger) {
    props.classes = {
      root: topClass,
    };
  }

  return cloneElement(children, props);
};

ElevationScroll.propTypes = {
  children: PropTypes.node.isRequired,
  topClass: PropTypes.string.isRequired,
  isFirstPage: PropTypes.bool.isRequired,
};

export default ElevationScroll;
