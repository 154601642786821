import { useLanguage } from './useLanguage';
import { defaultLangKey } from '../lib/languages';

const getPrefix = (language) => {
  switch (language) {
    case defaultLangKey:
      return '';
    default:
      return `/${language}`;
  }
};

const getPrefixFromValue = (language) => getPrefix(language);

const usePrefix = () => {
  const language = useLanguage();

  return getPrefix(language);
};

export default usePrefix;

export { getPrefixFromValue };
