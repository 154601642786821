import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { getCurrentLangKey } from 'ptz-i18n';
import { useCookies } from 'react-cookie';
import Helmet from 'react-helmet';

import {
  useSiteMetaData,
  LanguageProvider,
  getPrefixFromValue,
} from '../hooks';
import { theme } from '../lib';
import { Footer, Header } from '../components';

// Create font-awesome libary
library.add(fas);

const Layout = ({ children, location: { pathname } }) => {
  const { languages } = useSiteMetaData();
  const [cookies, setCookies] = useCookies(['language']);

  const langKey = getCurrentLangKey(
    languages.langs,
    languages.defaultLangKey,
    pathname
  );

  useEffect(() => {
    if (!cookies.language) {
      setCookies('language', langKey, { path: '/' });
    } else if (langKey !== cookies.language) {
      navigate(
        pathname.replace(
          getPrefixFromValue(langKey),
          getPrefixFromValue(cookies.language)
        ),
        { replace: true }
      );
    }
  }, [cookies.language, langKey, pathname, setCookies]);

  return (
    <LanguageProvider language={cookies.language || langKey}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <style type="text/css">
            {`
              #gatsby-focus-wrapper {
                  min-height: 100vh;
                  display: flex;
                  flex-direction: column;
              }
            `}
          </style>
        </Helmet>

        <CssBaseline />
        <Header pathname={pathname} />
        <main style={{ overflowX: 'hidden', flex: 1 }}>{children}</main>
        <Footer />
      </ThemeProvider>
    </LanguageProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Layout;
