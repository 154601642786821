const animations = {
  '@keyframes fadeInZoom': {
    '0%': {
      opacity: 0,
      transform: 'translate2d(0,-5px)',
      transform: 'scale3d(.96,.96,.96)', // eslint-disable-line no-dupe-keys
    },
    '100%': {
      opacity: 1,
    },
  },

  '@keyframes slideInFromLeft': {
    '0%': {
      transform: 'translateX(-100%)',
    },
    '100%': {
      opacity: 1,
    },
  },

  '@keyframes slideInFromRight': {
    '0%': {
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
    },
  },
};

export default animations;
