import makeStyles from '@material-ui/styles/makeStyles';
import green from '@material-ui/core/colors/green';

import animations from '../lib/animations';

const useStyles = makeStyles(({ palette, breakpoints, shadows }) => ({
  /* Header */

  brandContainerDesktop: {
    [breakpoints.down('450')]: {
      display: 'none',
    },
    position: 'absolute',
    height: '100%',
    left: 20,
    display: 'flex',
    alignItems: 'center',
  },

  toolbar: {
    [breakpoints.down('450')]: {
      justifyContent: 'space-between',
    },
    justifyContent: 'flex-end',
    alignItems: 'stretch !important',
  },

  tabs: {
    display: 'flex',
    [breakpoints.up('450')]: {
      marginRight: '1rem',
    },
  },

  appBarTop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
  },

  tab: {
    fontFamily: '"Palanquin Dark", sans-serif',
    color: palette.secondary.dark,
    textTransform: 'uppercase',
    textDecoration: 'none',
    [breakpoints.up('sm')]: {
      fontSize: '0.9rem',
    },
    [breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `2px solid transparent`,
  },

  activeTab: {
    color: palette.secondary.main,
    borderColor: palette.secondary.main,
  },

  selectIcon: {
    fill: `${palette.secondary.main} !important`,
  },

  selectRoot: {
    display: 'flex',
  },

  flag: {
    maxWidth: '100%',
    display: 'flex',
  },

  /* Intro text */

  animationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    [breakpoints.up('sm')]: {
      padding: '3vw',
    },
    [breakpoints.down('xs')]: {
      padding: '70px 3vw ',
    },
  },

  introText: {
    opacity: 0,
    marginLeft: '1rem',
    zIndex: 99,
  },

  /* ZoomInFade */

  fadeInZoom: {
    animationName: '$fadeInZoom',
    animationDuration: '.80s',
    animationFillMode: 'forwards',
    animationDelay: '.25s',
  },

  /* About us */

  slideInLeft: {
    animationName: '$slideInFromLeft',
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },

  slideInRight: {
    animationName: '$slideInFromRight',
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },

  title: {
    opacity: 0,
    display: 'flex',
    color: palette.primary.main,
    flex: 2,
    [breakpoints.up('md')]: {
      justifyContent: 'center',
    },
    [breakpoints.down('sm')]: {
      marginBottom: '1rem !important',
    },
    alignItems: 'start',
  },

  htmlContent: {
    [breakpoints.up('md')]: {
      padding: '0 20px',
      paddingTop: '10px',
    },
    opacity: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    fontSize: '1rem',
    fontFamily: '"Work Sans", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    '& p': {
      fontSize: 20,
      marginTop: 0,
    },
  },

  /* Footer */

  footer: {
    // marginTop: '2rem',
    backgroundColor: palette.primary.main,
    color: palette.secondary.main,
    padding: '2rem 2.5rem',
  },

  /* Customers */

  customerContainer: {
    opacity: 0,
    display: 'flex',
    filter: 'brightness(0)',
    justifyContent: 'center',
  },

  customer: {
    display: 'flex',
    padding: '0.5rem',
  },

  /* Contact field */

  contactFieldHalf: {
    [breakpoints.up('sm')]: {
      flexBasis: '50%',
      '&:nth-child(2n + 1)': {
        paddingRight: '1rem',
      },
      '&:nth-child(2n)': {
        paddingLeft: '1rem',
        '& > label': {
          paddingLeft: '1rem',
        },
      },
    },
    [breakpoints.down('xs')]: {
      flexBasis: '100%',
    },
  },

  formSubmitButton: {
    marginTop: '1rem !important',
    marginLeft: 'auto !important',
  },

  /* misc */

  paper: {
    marginBottom: '0.25rem',
    flexWrap: 'wrap',
    display: 'flex',
    borderRadius: 2,
    backgroundColor: '#fff',
    boxShadow: shadows[2],
    padding: '0.5rem 1.25rem 1.5rem 1.25rem',
  },

  overflowContainer: {
    position: 'absolute',
    width: '100%',
    [breakpoints.up('sm')]: {
      height: '25%',
    },
    [breakpoints.down('xs')]: {
      height: '35%',
    },
    background: palette.primary.main,
    zIndex: -1,
  },

  mt: {
    marginTop: '1rem !important',
  },

  success: {
    backgroundColor: `${green[600]} !important`,
  },

  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  },

  /* animations */
  ...animations,
}));

export default useStyles;
