import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#001A57',
      dark: '#00002e',
      light: '#3b4084',
    },
    secondary: {
      main: '#ffffff',
      dark: '#cccccc',
    },
  },
  typography: {
    h1: {
      marginBottom: '1.25rem',
      fontFamily: '"Palanquin Dark", sans-serif',
    },
    h2: {
      marginBottom: '0.75rem',
      fontFamily: '"Palanquin Dark", sans-serif',
    },
    h3: {
      marginBottom: '0.25rem',
      fontFamily: '"Palanquin Dark", sans-serif',
    },
    h4: {
      fontFamily: '"Palanquin Dark", sans-serif',
    },
    fontFamily: '"Work Sans", sans-serif',
  },
});

export default responsiveFontSizes(theme);
