import React from 'react';
import useStyles from '../../hooks/useStyles';

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      © {new Date().getFullYear()}, Big Data AB
    </footer>
  );
};

export default Footer;
