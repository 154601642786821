module.exports = [{
      plugin: require('../../layers/paketo-buildpacks_yarn-install/build-modules/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../layers/paketo-buildpacks_yarn-install/build-modules/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../layers/paketo-buildpacks_yarn-install/build-modules/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Big Data Consulting AB","short_name":"Big Data","start_url":"/","background_color":"#ffffff","theme_color":"#001A57","icon":"static/assets/favicon.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b3e55909cf6b434da439aab2661b9454"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
